<template>
<UserListPageView />
</template>

<script>
import UserListPageView from '@/modules/users/UserListPage.vue'
export default {
    name: 'UserListView',
    components: {
        UserListPageView
    }
}
</script>
