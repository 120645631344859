<template lang="html">
<LoaderComponent v-if="this.loader" />
<section class="section section-odd section-border">
    <div class="container">
        <h1 class="text-center">Contact Us</h1>
    </div>
</section>
<section class="section-row">
    <div class="container">
        <div class="layout-cols layout-cols-sd-block sd-block">
            <div class="layout-col-left">
                <div class="widget widget-white form-contact-us">
                    <div class="widget-body">
                        <div class="form">
                            <div class="form-header">
                                <h3>Any Queries? Send us details of your queries.</h3>
                            </div>
                            <div class="mb-2">
                                <div class="">
                                    <h4>Your Details</h4>
                                </div>
                                <div class="row infoDiv mb-2">
                                    <div class="col-md-6 col-lg-6 col-sm-6 col-12">
                                        <input name="name" type="text" maxlength="100" id="name"  v-model="form.name" class="input-control inputField circularfield" placeholder="Name">
                                        <div class="validator error" v-for="error of v$.form.name.$errors" :key="error.$uid">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-6 col-12">
                                        <input name="cellphone" type="text" maxlength="10" id="cellphone" v-model="form.mobile" class="input-control inputField circularfield" placeholder="Cell Phone Number">
                                        <div class="validator error" v-for="error of v$.form.mobile.$errors" :key="error.$uid">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row infoDiv mb-2">
                                <div class="col-md-6 col-lg-6 col-sm-6 col-12">
                                    <input name="company name" type="text" maxlength="100" id="companyname"  v-model="form.email" class="input-control inputField circularfield" placeholder="Company name (optional)">
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-6 col-12">
                                    <input name="email" type="text" maxlength="100" id="email" class="input-control inputField circularfield" placeholder="Email">
                                    <div class="validator error" v-for="error of v$.form.email.$errors" :key="error.$uid">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-rows">
                            <div class="mb-0">
                                <h4>Message</h4>
                            </div>
                            <div class="row m-b-no mt-0">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                                    <textarea name="message" rows="2" cols="20" id="message"   v-model="form.message" class="input-control circularfield" placeholder="Write your message here" style="height:100px;">
                                    </textarea>
                                    <div class="validator error" v-for="error of v$.form.message.$errors" :key="error.$uid">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="widget-footer clearfix">
                        <input type="submit" name="submitbutton" value="Submit" id="submitbutton" @click.prevent="submitQuery" class="btn btn-primary circularfield" style="float: right;">
                    </div>
                </div>
            </div>
            <div class="layout-col-right">
                <div class="list-featured icon-feature-div">
                    <i class="icon icon-telephone"></i>
                    <div class="desc">
                        <h3>Telephone</h3>
                        <span>xxx xxx xxxx</span>
                    </div>
                </div>
                <div class="list-featured icon-feature-div">
                    <i class="icon icon-envelope"></i>
                    <div class="desc">
                        <h3>Email</h3>
                        <span><a href="mailto:support@supplierdirect.uk">support@supplierdirect.uk</a></span>
                    </div>
                </div>
                <div class="list-featured icon-feature-div">
                    <i class="icon icon-printer"></i>
                    <div class="desc">
                        <h3>Fax</h3>
                        <span>xxx xxx xxxx</span>
                    </div>
                </div>
                <div class="list-featured icon-feature-div">
                    <i class="icon icon-road-sign"></i>
                    <div class="desc">
                        <h3>Physical Address</h3>
                        <span> Leeds, United Kingdom</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    helpers,
    minLength,
    maxLength,
    integer,
    email,
    sameAs
} from '@vuelidate/validators';
export default {
    name: 'ContactusPage',
    components: {
        LoaderComponent
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            loader: false,
            form: {
                name: '',
                email: '',
                mobile: '',
                message: '',
                companyname: ''
            },
            FORM_REQUIRED_FIELD:'This field is required'
        }
    },
    validations() {
        return {
            form: {
                name: {
                    required: helpers.withMessage(this.FORM_REQUIRED_FIELD, required)
                },
                email: {
                    required: helpers.withMessage(this.FORM_REQUIRED_FIELD, required),
                    containEmail: helpers.withMessage(
                        () => `Not Valid Format`,
                        email
                    ),
                },
                mobile: {
                    required: helpers.withMessage(this.FORM_REQUIRED_FIELD, required),
                    integer,
                    minLength: minLength(10),
                    maxLength: maxLength(10),
                },
                message: {
                    required: helpers.withMessage(this.FORM_REQUIRED_FIELD, required)
                },
            }
        }
    },
    methods: {
        async submitQuery() {
            const result = await this.v$.form.$validate();
            if (result == false) {
                return
            }
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('mobile', this.form.mobile);
            formData.append('companyname', this.form.companyname);
            formData.append('email', this.form.email);
            formData.append('message', this.form.message);
            this.loader = true;
            this.axios.post(process.env.VUE_APP_API_ENDPOINT + `/contact-us`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((response) => {
                    this.loader = false;
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Query submitted successfully!',
                        showConfirmButton: true,
                        timer: 5000
                    }).then(() => {
                        console.log('then');
                    })
                }).catch((error) => {
                    console.log('error');
                });
        }
    },

};
</script>

<style lang="scss" scoped>
.widget-body {
    padding: 3rem !important;
}

.form-rows {
    padding-top: 7px !important;
}

.widget-footer {
    padding: 15px 0px !important;
    margin-right: 29px !important;
    margin-left: 29px !important;
}

.list-featured .desc h2,
.list-featured .desc span {
    text-wrap: nowrap !important;
}

.icon-feature-div .desc {
    padding-left: 60px !important;
}

.list-featured i {
    font-size: 25px !important;
}
</style>
