<template>
<AccountSettings />
</template>

<script>
import AccountSettings from '@/modules/settings'
export default {
    name: 'AccountSettingsView',
    components: {
        AccountSettings
    }
}
</script>
