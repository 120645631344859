<template>
<ShowProductPage />
</template>

    
<script>
import ShowProductPage from '@/modules/lookupvalues/ShowProductPage.vue'
export default {
    name: 'AddCategoryView',
    components: {
        ShowProductPage
    }
}
</script>
