<template lang="html">
<div class="container">
    <div class="form form-login m-auto loginFormDiv ">
        <div class="card logsignupFormCard">
            <div class="row">
                <div class="col-md-12 mt-0">
                    <div class="form">
                        <div class="row">
                            <div class="col-md-5 rightSideCardContainer leftsideloginDiv">
                                <div class="contentDiv">
                                    <div class="cardContent">
                                        <div class="col d-none d-lg-flex border-start align-items-center justify-content-between flex-column text-center leftsidecontentDiv ">
                                            <div class="logo">
                                                <router-link to="/" class="Logo">
                                                    <img width="180" :src="src" alt="SupplierDirect">
                                                </router-link>
                                            </div>
                                            <div>
                                                <h3 class="fw-bold">Welcome to SupplierDirect!</h3>
                                                <p class="lead my-2">Already have an account?</p>
                                                <router-link to="/login" class="btn btn-primary mb-5 mt-8 circularfield">Login Here</router-link>
                                            </div>
                                            <router-link to="/privacy-policy" class="privacyitem mt-0">Privacy Policy</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 leftSideCardContainer rightsideloginDiv regsideloginDiv">
                                <div class="contentDiv">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col-md-10 offset-md-1">
                                                <div class="d-block d-lg-none text-center text-lg-start">
                                                    <router-link to="/" class="Logo">
                                                    <img width="120" :src="src" alt="SupplierDirect">
                                                    </router-link>
                                                </div>
                                                <div class="text-center text-lg-start">
                                                    <h1 class="display-8 text-center">Sign Up</h1>
                                                    <p class="text-muted text-center">Sign up to SupplierDirect to continue</p>
                                                    <div class="form-row text-center ml-8">
                                                        <div class="row userSelectionshown">
                                                            <div class="col-md-6">
                                                                <input type="checkbox" v-model="this.userRole[3]" id="customerCheck" name="checkUserRole" 
                                                                :checked="(this.userRole[3]==true)?true:false" 
                                                                :disabled="this.rolename=='Supplier'?true:false" />
                                                                <label for="customerCheck" class="checkbox" :class="this.rolename=='Supplier'?'disbaledRole':''">Customer</label>
                                                            </div> 
                                                            <div class="col-md-6">
                                                                <input type="checkbox" v-model="this.userRole[2]" id="suppCheck" name="checkUserRole" 
                                                                :checked="(this.userRole[2]==true)?true:false"  :disabled="this.rolename=='Customer'?true:false" 
                                                                  />
                                                                <label for="suppCheck" class="checkbox" :class="this.rolename=='Customer'?'disbaledRole':''">Supplier</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center text-lg-start">
                                                    <p class="text-muted text-center text-green">
                                                        <span v-if="this.otpBox">OTP has been sent to your given email address.</span>
                                                        <span class="text-red" v-else-if="this.roleerror">Please select role type.</span>
                                                    </p>
                                                </div>
                                                <div class="form-rows p-t-no" v-if="this.otpBox">
                                                    <div class="form-row">
                                                        <input name="otpbox" type="text" v-model="this.regOtp" id="otpbox" class=" input-control inputField circularfield" placeholder="Enter OTP">
                                                        <div class="validator error" v-for="error of v$.regOtp.$errors" :key="error.$uid">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <input type="submit" name="submitbtn" value="SIGN UP" id="submitbtn" class="btn btn-primary block circularfield" @click.prevent="handleOTPRegistration">
                                                    </div>
                                                </div>
                                                <div class="form-rows p-t-no" v-else>
                                                    <div class="form-row">
                                                        <input name="name" type="text" v-model="form.name" id="name" class=" input-control inputField circularfield" placeholder="Name">
                                                        <div class="validator error" v-for="error of v$.form.name.$errors" :key="error.$uid">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <input name="username" type="text" v-model="form.username" id="username" class=" input-control inputField circularfield" placeholder="Username">
                                                        <div class="validator error" v-for="error of v$.form.username.$errors" :key="error.$uid">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <input type="text" class="input-control inputField circularfield" id="phone_number" v-model="form.phone_number" placeholder="Your Phone Number">
                                                        <div class="validator error" v-for="error of v$.form.phone_number.$errors" :key="error.$uid">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <input name="email" type="text" v-model="form.email" id="email" class=" input-control inputField circularfield" placeholder="Your Email Address">
                                                        <div class="validator error" v-for="error of v$.form.email.$errors" :key="error.$uid">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <input v-if="showPassword" type="text" name="password" class="input-control inputField circularfield" id="password" placeholder="Password" v-model="form.password" />
                                                        <input v-else type="password" class="input-control inputField circularfield" name="password" placeholder="Password" id="password" v-model="form.password">
                                                        <div class="eyeIcon2 mt-3" @click="toggleShow">
                                                            <span class="icon is-small is-right">
                                                                <font-awesome-icon :icon="['fas', 'eye-slash']" v-if="!showPassword" />
                                                                <font-awesome-icon :icon="['fas', 'eye']" v-if="showPassword" />
                                                            </span>
                                                        </div>
                                                        <div class="validator error" v-for="error of v$.form.password.$errors" :key="error.$uid">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <input v-if="showConfirmPassword" type="text" class="form-control inputField circularfield" id="confirmpswd" name="confirmpswd" v-model="form.confirmpassword" placeholder="Confirm Password" />
                                                        <input v-else type="password" class="input-control inputField circularfield" id="confirmpswd" v-model="form.confirmpassword" name="confirmpswd" placeholder="Confirm Password" />
                                                        <div class="eyeIcon2 mt-3" @click="toggleShowConfirmpswd">
                                                            <span class="icon is-small is-right mt-3">
                                                                <font-awesome-icon :icon="['fas', 'eye-slash']" v-if="!showConfirmPassword" />
                                                                <font-awesome-icon :icon="['fas', 'eye']" v-if="showConfirmPassword" />
                                                            </span>
                                                        </div>
                                                        <div class="validator error" v-for="error of v$.form.confirmpassword.$errors" :key="error.$uid">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <input type="submit" name="submitbtn" value="SIGN UP" id="submitbtn" class="btn btn-primary block circularfield" @click.prevent="handleRegistration">
                                                    </div>
                                                </div>
                                                <p class="text-center d-block d-lg-none mt-0 mt-lg-0">
                                                    Already have an account?<router-link to="/login" class="linkText">Login</router-link>.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    helpers,
    minLength,
    maxLength,
    integer,
    email,
    sameAs
} from '@vuelidate/validators';
import {watch} from 'vue';
export default {
    name: 'SignUpComponent',
    components: {},
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    computed: {
        buttonLabel() {
            return (this.showPassword) ? "Hide" : "Show";
        }
    },
    data: () => ({
        src: require('@/assets/img/ecom-logo.png'),
        userRole:{},
        roleerror:false,
        usertype: '',
        otpBox:false,
        regOtp:null,
        role_id: null,
        value: null,
        form: {
            name: '',
            username: '',
            phone_number:'',
            email: '',
            password: '',
            confirmpassword: '',
        },
        userId:null,
        rolename:'',
        showPassword:false,
        showConfirmPassword:false,
        FORM_REQUIRED_FIELD: 'This field is required',
        FORM_CONFIRM_PASSWORD_FIELD: 'Confirm Password is required'
    }),
    validations() {
        return {
            form: {
                name: {
                    required: helpers.withMessage(this.FORM_REQUIRED_FIELD, required)
                },
                username: {
                    required: helpers.withMessage(this.FORM_REQUIRED_FIELD, required)
                },
                phone_number:{
                    required: helpers.withMessage(this.FORM_REQUIRED_FIELD, required),
                    integer,
                    minLength:minLength(10),
                    maxLength:maxLength(10)
                },
                password: {
                    minLength: minLength(8),
                    maxLength: maxLength(12),
                    containsPasswordRequirement: helpers.withMessage(
                        () => `(Minimum of 8 character long) must include at least \
    - One uppercase letter,
    - One lowercase letter, 
    - One number, 
    - One symbol (such as @, #, $, %, etc.).`,
                        (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(value)
                    ),
                },
                confirmpassword: {
                    required: helpers.withMessage(this.FORM_CONFIRM_PASSWORD_FIELD, required),
                    sameAsPassword: helpers.withMessage(
                        "Confirm password must be same as password.",
                        sameAs(this.form.password)
                    )
                },
                email: {
                    required: helpers.withMessage(this.FORM_REQUIRED_FIELD, required),
                    containEmail: helpers.withMessage(
                        () => `Not Valid Format`,
                        email
                    ),
                },
            },
            regOtp:{
                required: helpers.withMessage(this.FORM_REQUIRED_FIELD, required),
                integer,
            }
        }
    },
    watch:{
        userRole:{
        handler(newval, oldval) {
            console.log('newval-',newval, this.userRole);
            if(Object.keys(this.userRole).length==0){
                this.roleerror = true;
            }
            else{
                this.roleerror = false;
            }
            let v = [], AllvalFalse;
            const allFalse = Object.values(newval).forEach(value =>{
                if(value === false){
                    v.push(value)
                }
            });
            if(v.length>1){
                AllvalFalse = true;
            }
            if (AllvalFalse) {
                this.userRole = Object.assign({},{});

            }
        },
        deep: true
    }
    },
    methods: {
        async handleRegistration() {
            console.log('this.userRole.-',(Object.keys(this.userRole)).length==0)
            if(Object.keys(this.userRole).length==0){
                this.roleerror = true;
            };
            const result = await this.v$.form.$validate();
            if (result == false ||  this.roleerror) {
                return
            }
            let formData = new FormData();
            let role_id=[]; 
            for(const[index,value] of Object.entries(this.userRole)){
                role_id.push(index);
            }
            formData.append('role_ids', role_id);
            formData.append('name', this.form.name);
            formData.append('username', this.form.username);
            formData.append('phone_number', this.form.phone_number);
            formData.append('email', this.form.email);
            formData.append('password', this.form.password);
            formData.append('confirmpassword', this.form.confirmpassword);
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            this.axios.post(process.env.VUE_APP_API_ENDPOINT + '/register', formData, {
                headers
            }).then((resp) => {
               console.log('register-resp-', resp);
                this.$swal({
                    icon: 'success',
                    title: 'User registered successfully!',
                    showConfirmButton: true,
                    confirmButtonColor: 'rgb(2 145 2)',
                }).then(() => {
                    this.form.name = '';
                    this.form.username = '';
                    this.form.email = '';
                    this.form.password = '';
                    this.form.confirmpassword = '';
                    this.roleerror = false;
                    this.$router.push({
                        path: 'login'
                    });
                })
            }).catch((error) => {
                console.log('error-', error.response,  error.response.data.message)
                if(error.response.data.data){
                    if(error.response.data.message.rolename){
                        this.$swal.fire({
                            icon: error.response.data.message.roleyexit?'error':'info',
                            title: error.response.data.data,
                            text: 'Do you want to become a '+ error.response.data.message.rolename + '?',
                            showConfirmButton: true,
                            showCancelButton:true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            customClass: 'warningRegSwal'
                            // timer: 5000
                        }).then((result) => {
                            if(result.isConfirmed){
                                this.userId=  error.response.data.message.userid;
                                this.rolename=error.response.data.message.rolename;
                                let formData = new FormData();
                                formData.append('email', this.form.email);
                                formData.append('flag', 'registration');
                                formData.append('rolename',error.response.data.message.rolename);
                                const headers = {
                                    'Content-Type': 'multipart/form-data',
                                }
                                this.axios.post(process.env.VUE_APP_API_ENDPOINT + '/send-otp', formData, {
                                    headers
                                }).then((resp) => {
                                    this.userRole = Object.assign({},{});
                                    this.userRole[error.response.data.message.rolename=='Supplier'?2:3] = true;
                                    this.otpBox = true;
                                })
                                .catch((error)=>{
                                    console.log('error')
                                });
                            }
                        });
                    }
                    if (error.response.data.data.phone_number) {
                            if (error.response.data.data.phone_number[0] == 'The phone number has already been taken.') {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Phone Number already exist',
                                    showConfirmButton: true,
                                    timer: 5000
                                }).then(() => {
                                    this.form.phone_number = "";
                                });
                            }
                        }    
                    if (error.response.data.data.username) {
                        if (error.response.data.data.username[0] = 'The username has already been taken.') {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Username Already Exist',
                                showConfirmButton: true,
                                timer: 5000
                            }).then(() => {
                                this.form.username = "";
                            })
                        }
                    }
                    if (error.response.data.data.email) {
                        if (error.response.data.data.email[0] = 'The email has already been taken.') {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Email Id Already Exist',
                                showConfirmButton: true,
                                timer: 5000
                            }).then(() => {
                                this.form.email = "";
                            })
                        }
                    }

                }

            });
        },
        async handleOTPRegistration() {
            const result = await this.v$.regOtp.$validate();
            if (result == false) {
                return
            }
            let formData = new FormData();
            let role_id=[]; 
            for(const[index,value] of Object.entries(this.userRole)){
                role_id.push(index);
            }
            formData.append('email', this.form.email);
            formData.append('password', this.form.password);
            formData.append('otp', this.regOtp);
            formData.append('role_id', role_id);
            formData.append('user_id', this.userId);
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            await this.axios.post(process.env.VUE_APP_API_ENDPOINT+'/otp-register', formData,{
                headers
            }).then((resp) => {
               console.log('register-resp-', resp);
                this.$swal({
                    icon: 'success',
                    title: 'You are now a '+this.rolename+' too for Supplier direct',
                    showConfirmButton: true,
                    confirmButtonColor: 'rgb(2 145 2)',
                }).then(() => {
                    this.form.name = '';
                    this.form.username = '';
                    this.form.email = '';
                    this.form.password = '';
                    this.form.confirmpassword = '';
                    this.regOtp = '';
                    this.otpBox = false;
                    this.roleerror = false;
                    this.$router.push({
                        path: 'login'
                    });
                })
            }).catch((error) => {
                console.log('error-', error.response)
                if(error.message == "Network Error"){
                    localStorage.clear();
                    if (this.$route.name != 'home') {
                            this.$router.push({
                            name: 'home'
                        });
                    } 
                }
                if (error.response.data.message == 'OTP does not exist') {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'OTP does not exist.',
                            showConfirmButton: true,
                            timer: 5000
                        }).then(() => {
                            this.regOtp = '';
                        });
                        return;
                    }
                    if (error.response.data.message == 'OTP is not valid') {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Invalid OTP.',
                            html: '<h5>Your otp is invalid, now you can resend the otp.</h5>',
                            showConfirmButton: true,
                            timer: 5000
                        }).then(() => {
                            this.regOtp = '';
                            this.otpBox = false;
                        });
                    }
            });
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        toggleShowConfirmpswd() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
    },
    mounted() {
        // this.usertype = document.querySelector('input[name="checkUserRole"]:checked').value;
        // document.querySelectorAll("input[name='checkUserRole']").forEach((input) => {
        //     input.addEventListener('change', (e) => {
        //         this.usertype = e.target.defaultValue;
        //     });
        // });
    }
};
</script>

<style scoped>
input[type=checkbox] {
    display: none;
    }

label.checkbox {
    position: relative;
    display: inline-block;
    /* margin-right: 247px; */
    text-indent: 30px;
    overflow: visible;
    cursor: pointer;
    /* margin-left: -75px; */
    }
    label.checkbox:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 20%;
    background: #112785;
    }
    label.checkbox:after {
    content: "";
    position: absolute;
    width: 9px;
    height: 4px;
    top: 9px;
    left: 6px;
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    opacity: 0;
    }
    input[type=checkbox]:checked + label:after {
    opacity: 1;
    }
    label.checkbox{
        font-size: 14px !important;
    }
    label.disbaledRole{
    cursor: not-allowed !important;
}

</style>
