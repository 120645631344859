<template>
<FavouriteSpplierPage />
</template>

    
<script>
import FavouriteSpplierPage from '@/modules/favsupplist/FavouriteSpplierPage.vue'
export default {
    name: 'FavouriteSpplierPageView',
    components: {
        FavouriteSpplierPage
    }
}
</script>