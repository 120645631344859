<template>
    <FavSupplierListView />
    </template>
    
    <script>
    import FavSupplierListView from '@/modules/favsupplist/FavSupplierListView.vue'
    export default {
        name: 'FavSupplierListViewPage',
        components: {
            FavSupplierListView
        }
    }
    </script>
    