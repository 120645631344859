<template>
<AdminView />
</template>

<script>
import AdminView from "./_components/AdminView.vue"
export default {
    name: "AdminModule",
    components: {
        AdminView
    }
}
</script>
