<template>
<AccountSettingView />
</template>

<script>
import AccountSettingView from "./_components/AccountSettingView.vue"
export default {
    name: "AccountSettingModule",
    components: {
        AccountSettingView
    }
}
</script>
