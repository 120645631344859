<template lang="html">
    <Faq v-if="this.$route.path.includes('/faq')" />
<PrivacyPolicy v-else/>
</template>

<script>
import PrivacyPolicy from '@/components/PrivacyPage.vue';
import Faq from '@/components/FaqComponent.vue';
export default {
    name: 'PrivacyPolicyModule',
    components: {
        PrivacyPolicy,
        Faq
    },
    transition: 'zoom',
};
</script>

<style lang="scss" scoped></style>
