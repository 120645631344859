<template lang="html">
<FooterWidgets />
</template>

<script>
import FooterWidgets from '@/components/FooterWidgets.vue';
export default {
    name: 'FooterDefault',
    components: {
        FooterWidgets
    }
};
</script>

<style lang="scss" scoped></style>
