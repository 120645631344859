<template>
<HomeView />
</template>

<script>
import HomeView from "./_components/HomeView.vue"
export default {
    name: "HomeViewModule",
    components: {
        HomeView
    }
}
</script>
