<template>
<AboutusView />
</template>

<script>
import AboutusView from '@/modules/aboutus'
export default {
    name: 'AboutUsView',
    components: {
        AboutusView
    }
}
</script>
