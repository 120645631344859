<template>
<ResponseQuotesPageView />
</template>

<script>
import ResponseQuotesPageView from '@/modules/orderlist/quotes/ResponseQuotesModule.vue'
export default {
    name: 'ResponseQuotesListView',
    components: {
        ResponseQuotesPageView
    }
}
</script>
