<template>
<HeaderActionView />
</template>

<script>
import HeaderActionView from "./_components/HeaderActionView.vue"
export default {
    name: "HeaderActionModule",
    components: {
        HeaderActionView
    }
}
</script>
