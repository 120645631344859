<template lang="html">
<ContactUs />
</template>

<script>
import ContactUs from '@/components/ContactUsPage.vue';
export default {
    name: 'ContactUsModule',
    components: {
        ContactUs
    },
    transition: 'zoom',
};
</script>

<style lang="scss" scoped></style>
