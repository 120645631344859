<template>
<WerehouseView />
</template>

<script>
import WerehouseView from '@/modules/lookupvalues/WerehousePage.vue'
export default {
    name: 'WerehouseViewPage',
    components: {
        WerehouseView
    }
}
</script>
