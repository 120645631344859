<template>
<QuestionnairePage />
</template>

    
<script>
import QuestionnairePage from '@/modules/users/QuestionnairePage.vue'
export default {
    name: 'QuestionnairePageView',
    components: {
        QuestionnairePage
    }
}
</script>
