<template>
<AddUserPage />
</template>

    
<script>
import AddUserPage from '@/modules/users/AddUserPage.vue'
export default {
    name: 'AddUserView',
    components: {
        AddUserPage
    }
}
</script>
