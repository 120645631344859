<template lang="html">
<home-page />
</template>

<script>
import HomePage from '@/components/HomePage.vue';
export default {
    components: {
        HomePage,
    },
    data: () => {
        return {};
    },
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
