<template lang="html">
<ApprovedOrdersPage />
</template>

<script>
import ApprovedOrdersPage from '@/components/orderslist/order/ApprovedOrdersPage.vue';
export default {
    components: {
        ApprovedOrdersPage
    },
    data: () => {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
