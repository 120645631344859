<template>
<ChangePassword />
</template>

<script>
import ChangePassword from '@/modules/forgetpswd/ChangePassword.vue'
export default {
    name: 'ChangePasswordView',
    components: {
        ChangePassword
    }
}
</script>
