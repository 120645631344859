<template>
<v-row justify="center">
    <v-dialog persistent v-model="show" width="860px" class="modalShowContainer">
        <v-card>
            <v-card-title>
                <span class="text-h5 feedbackhead">
                    <h4>Feedback </h4>
                </span>
                <font-awesome-icon :icon="['fasl', 'xmark']" class="modalCloseIcon" @click="close()" />
            </v-card-title>
            <v-card-text>
                <v-container>
                    <section class="widget widget-white feedbackmodalwidget">
                        <div class="widget-body headingSection">
                            <div class="d-flex mb-2 justify-content-between">
                                <div class="devideDiv" :class="this.ratesupp?'activesection':'deactive'" @click="selectRate" >
                                    <font-awesome-icon :icon="['fas', 'star']" class="starIcon"/>
                                    Please rate this Supplier
                                </div>
                                <div class="d-flex flex-column mb-2">
                                    <div class="flex-wrap devideDiv" :class="this.markasfav?'activesection':'deactive'">
                                            <!--icon-->
                                            <font-awesome-icon :icon="['fas', 'heart']" />
                                            Mark as favourite
                                    </div>
                                </div>
                            </div>
                            <div class="form">
                                <div class="form-rows p-t-no" v-if="this.markasfav">
                                <div class="table-responsive ps-table mt-0 favListTable">
                                    <DataTable :value="items" showGridlines :rows="5" tableStyle="min-width: 50rem">
                                        <Column field="" header="#" style="width: 25%">
                                            <template #body="item">
                                                <div class="actionsContainer d-flex align-items-center">
                                                    <font-awesome-icon :icon="['fas', 'heart']" :id="item.id" 
                                                    v-if="item.data.active && item.data.suppfavorited" :class="item.data.active && item.data.suppfavorited?'markedHeart':''"/>
                                                    <font-awesome-icon :icon="['fas', 'heart']" :id="item.id" 
                                                    v-else-if="item.data.active && !item.data.suppfavorited" @click="deleteFavSupp(item.data.id)"/>
                                                    <font-awesome-icon :icon="['far', 'heart']" class="unFav" :id="item.id" 
                                                    v-else @click="addFavSupp(item.data.id)"/>
                                                </div>
                                            </template>
                                        </Column>
                                        <!-- <Column field="id" header="Id"></Column> -->
                                        <Column field="refid" header="Ref Id"></Column>
                                        <Column field="fav_by" header="Favourited By">
                                            <template #body="item">
                                                <div class="operation-wrapper text-center">
                                                    <div class="actionsContainer d-flex align-items-center gap-3">
                                                        <span>{{ item.data.fav_by }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </Column>
                                        <template #empty v-if="this.testflag == ''">
                                        </template>
                                        <template #empty v-else>
                                            <div class="messagediv mt-8">
                                                <h3>No Data Found</h3>
                                            </div>
                                        </template>
                                    </DataTable>
                                </div>
                                </div>
                                <div class="form-rows p-t-no" v-else>
                                    <div class="form-row">
                                        <div class="d-flex mb-5">
                                            <label for="pricerating" class="col-sm-2 col-form-label">Price</label>
                                            <span>Rate This:{{ this.pricerating }}</span>
                                            <div class="col-sm-6">
                                                <v-rating v-model="pricerating" color="yellow-darken-3" id="pricerating" half-increments></v-rating>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-5">
                                            <label for="qualityrating" class="col-sm-2 col-form-label">Quality</label>
                                            <span>Rate This:{{ this.qualityrating }}</span>
                                            <div class="col-sm-6">
                                                <v-rating v-model="qualityrating" color="yellow-darken-3" id="qualityrating" half-increments></v-rating>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-5">
                                            <label for="timerating" class="col-sm-2 col-form-label">Time</label>
                                            <span>Rate This:{{ this.timerating }}</span>
                                            <div class="col-sm-6">
                                                <v-rating v-model="timerating" color="yellow-darken-3" id="timerating" half-increments></v-rating>
                                            </div>
                                        </div>
                                        <div class="align-center col-12 col-sm-12 col-md-12 col-lg-12  mb-0">
                                            <button id="feedback" name="feedback" class="btn btn-primary
                                              block circularfield align-center" v-b @click="sendfeedback">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-row>
</template>

<script>
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import {
    ref,
    watch
} from "vue";
export default {
    name: 'FeedBack',
    components: {},
    props: {
        visible: {
            type: String
        },
        categoryid:{
            type:Number
        },
        orderid:{
            type:Number
        }
        // suppfavorited:{
        //     type:Boolean
        // }
    },
    data: () => ({
        pricerating: 0,
        qualityrating: 0,
        timerating: 0,
        markasfav:false,
        ratesupp:true,
        items:[],
        usertype:'',
        suppier_Id:null,
        category_Id:null,
        favsupplier:false,
        suppfavorited:false
    }),
    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$emit('close');
                }
            },
        },
    },
    methods: {
        addFavSupp(id){
            let user_id = localStorage.getItem('loginid');
            if((this.items.filter(item=>item.active==true)).length>=3){
                this.$swal({
                title: 'At once you could have only three favourite suppliers.',
                text:'Please remove one to mark this one as favourite.',
                type: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: 'warningswal'
               });
               return  this.items;
            }
            else{
                this.$swal({
                    title: 'Do you want to add this supplier into your favourite list?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                    customClass: 'warningswal'
                }).then((result) => {
                    if(result.isConfirmed){
                        let formData = new FormData();
                        let supplier_Id = this.$route.query.supplierid?this.$route.query.supplierid:null;
                        let categoryId = this.$route.query.categoryid?this.$route.query.categoryid:null;
                        formData.append('supplierid', supplier_Id);
                        formData.append('categoryid',  this.category_Id);
                        formData.append('cust_id', user_id);
                        var token = localStorage.getItem("token");
                        const headers = {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'multipart/form-data'
                        };
                        this.axios.post(
                            process.env.VUE_APP_API_ENDPOINT + `/add_favsupp`,formData, {
                                headers
                            })
                            .then(response => {
                                console.log('response-', response.data.data);
                                if (response.status == 200) {
                                    this.favsupplier = true
                                    this.getFeedback();
                                    this.getSuppByOrderId();
                                //    this.close();
                                if(this.pricerating != 0|| this.timerating !=0||this.qualityrating!=0){
                                    this.close();
                                    this.$router.push({
                                        path: '/buyer/order'
                                    })
                                }
                                }
                            })
                            .catch(error => {
                                if (error.response) {
                                    if (error.response.status == 401 || error.response.status == 404 || error.response.status == 400) {
                                        localStorage.clear();
                                        if (this.$route.name != 'home') {
                                            this.$router.push({
                                                name: 'home'
                                            });
                                        }
                                    }
                                } else {
                                    if (error.message == "Network Error") {
                                        localStorage.clear();
                                        if (this.$route.name != 'home') {
                                            this.$router.push({
                                                name: 'home'
                                            });
                                        }
                                    }
                                }
                            });
                    } 
                })
                .catch((error)=>{  console.error('An error occurred:', error);});
            }
        },
        deleteFavSupp(id){
            let user_id = localStorage.getItem('loginid');
            this.$swal({
                title: 'Do you want to remove this supplier from your favourite list?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: 'warningswal'
            }).then((result) => {
                if(result.isConfirmed){
                    let formData = new FormData();
                    let supplier_Id = this.$route.query.supplierid?this.$route.query.supplierid:null;
                    let categoryId = this.$route.query.categoryid?this.$route.query.categoryid:null;
                    formData.append('user_id', user_id);
                    formData.append('supplierid', supplier_Id);
                    var token = localStorage.getItem("token");
                    const headers = {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data'
                    };
                    this.axios.post(
                        process.env.VUE_APP_API_ENDPOINT + `/delete_favsupp/${categoryId}`,formData, {
                            headers
                        })
                        .then(response => {
                            console.log('response-', response.data.data);
                            if (response.status == 200) {
                                this.getAllFavSuppliersList();
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.status == 401 || error.response.status == 404 || error.response.status == 400) {
                                    localStorage.clear();
                                    if (this.$route.name != 'home') {
                                        this.$router.push({
                                            name: 'home'
                                        });
                                    }
                                }
                            } else {
                                if (error.message == "Network Error") {
                                    localStorage.clear();
                                    if (this.$route.name != 'home') {
                                        this.$router.push({
                                            name: 'home'
                                        });
                                    }
                                }
                            }
                        });
                } 
            })
            .catch((error)=>{  console.error('An error occurred:', error);});
        },
        markAsFav(){
            this.markasfav=true;
            this.ratesupp=false;
        },
        selectRate(){
            this.ratesupp=true;
            this.markasfav = false;
        },
        close() {
            if(this.pricerating != 0|| this.timerating !=0||this.qualityrating!=0){
                this.$emit('close');
                this.$router.push({
                    path: '/buyer/order'
                })
            }
            else{
                this.$emit('close');
            }
        },
        sendfeedback() {
            console.log('this.pricerating-',this.pricerating);
            if(this.pricerating != 0|| this.timerating !=0||this.qualityrating!=0){
                let formData = new FormData();
                var token = localStorage.getItem("token");
                var orderid = this.$route.query.orderid;
                formData.append('order_id', orderid);
                formData.append('price_rating', this.pricerating);
                formData.append('time_rating', this.timerating);
                formData.append('quality_rating', this.qualityrating);
                const headers = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                };
                this.axios.post(
                        process.env.VUE_APP_API_ENDPOINT + `/feedback`, formData, {
                            headers
                        })
                    .then(response => {
                        this.$swal({
                            icon: 'success',
                            title: 'Sent feedback successfully!',
                            showConfirmButton: true,
                            confirmButtonColor: 'rgb(2 145 2)',
                            timer: 5000
                        }).then(() => {
                            this.markasfav=true;
                            this.ratesupp=false;
                            // this.close();
                            // this.$router.push({
                            //     path: '/buyer/order'
                            // })
                        });
                    })
                    .catch(err => {});
            }else{
                this.$swal({
                    icon: 'info',
                    title: 'Please rate atleast half star to submit',
                    showConfirmButton: true,
                    confirmButtonColor: 'rgb(2 145 2)',
                });
                return;
            }
        },
        getAllFavSuppliersList(){
            console.log('this.categoryid--', this.categoryid);
            var token = localStorage.getItem("token");
            let formData = new FormData();
            formData.append('user_id', localStorage.getItem('loginid'));
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            };
            this.axios.post(
                    process.env.VUE_APP_API_ENDPOINT + `/favsupplier-list/${this.categoryid}`,formData, {
                        headers
                    })
                .then(response => {
                    console.log('response-', response.data.data);
                    if (response.status == 200) {
                        let data = response.data.data;
                        this.items=data.map((el) => {
                            return{
                                id:el.supplier_id,
                                'refid':el.userrole.ref_id,
                                'fav_by':el.favorited_count,
                                'active':true
                            }
                        });
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401 || error.response.status == 404 || error.response.status == 400) {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    } else {
                        if (error.message == "Network Error") {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    }
                });
        },
        getSuppByOrderId(){
            var token = localStorage.getItem("token");
            let orderid = this.$route.query.orderid ? this.$route.query.orderid:null;
            let formData = new FormData();
            formData.append('user_id', localStorage.getItem('loginid'));
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            };
            this.axios.post(
                    process.env.VUE_APP_API_ENDPOINT + `/getsupplier/${orderid}`, formData,{
                        headers
                    })
                .then(response => {
                    console.log('//response-', response.data.data);
                    if (response.status == 200) {
                        let data = response.data.data;
                        let supplierId = data[0].supplier_id;
                        // Find the index of the existing supplier in this.items
                        let existingIndex = this.items.findIndex(item => item.id === supplierId);
                        if (existingIndex !== -1) {
                            this.items.splice(existingIndex, 1);
                        }
                        this.suppier_Id = data[0].supplier_id;
                        this.category_Id = data[0].category_id;
                        this.favsupplier = data[0].favorited_by==='you'?true:false;
                        console.log('data[0]-', data[0],   this.favsupplier, this.suppfavorited )
                        let dataitems={
                            id:data[0].supplier_id,
                            'refid':data[0].userrole.ref_id,
                            'fav_by':data[0].favorited_count,
                            'active':data[0].favorited_by==='you'?true:false,
                            'suppfavorited':this.suppfavorited && this.favsupplier?true:false
                        }
                        console.log('dataitems-', dataitems);
                        this.items.push(dataitems);
                        console.log('this.items-',this.items)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 401 || error.response.status == 404 || error.response.status == 400) {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    } else {
                        if (error.message == "Network Error") {
                            localStorage.clear();
                            if (this.$route.name != 'home') {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        }
                    }
                });
        },
        async getFeedback(){
            let orderid = '';
            if(this.$route.query.orderid){
                orderid = this.$route.query.orderid;
            }
            let supplier_Id = this.$route.query.supplierid?this.$route.query.supplierid:null;
            let categoryId = this.$route.query.categoryid?this.$route.query.categoryid:null;

            let loginid = localStorage.getItem('loginid');
            let formData = new FormData();
            formData.append('orderid', orderid);
            formData.append('supplier_id', supplier_Id);
            formData.append('category_id', categoryId);
            formData.append('user_id', loginid);
            var token = localStorage.getItem("token");
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            };
            await this.axios.post(
                process.env.VUE_APP_API_ENDPOINT + '/getfeedback', formData,{
                    headers
                })
                .then((resp)=>{
                    this.suppfavorited= resp.data.data.suppfavorited;
                })
                .catch((error)=>{});
        },
    },
    mounted() {
        console.log('thiscat-', this.categoryid);
        this.usertype = localStorage.getItem('userType');  
        this.getAllFavSuppliersList();
        this.getFeedback();
        this.getSuppByOrderId();
    }
}
</script>
<style scoped>
.devideDiv{
    font-size: 18px;
    cursor: pointer;
}
.devideDiv svg.starIcon{
    color: rgb(255,197, 3) !important;
}
.devideDiv svg{
    color: rgb(197, 2, 2) !important;
    font-size: 20px;
    cursor: pointer;
}
.activesection{
    font-weight: bolder;
    /* color: #00a7ff; */
    color: #0017ff;
}
.actionsContainer svg{
    color: rgb(197, 2, 2) !important;
    font-size: 17px;
    cursor: pointer;
}
.ps-table.favListTable tbody tr td{
    font-size: 15px !important;
}

/* .deactive svg.starIcon{
   color:  rgb(226 199 107) !important;
} */
/* .deactive{
    color: #9d9d9d !important;
} */
/* .deactive svg{
    color: rgb(224 93 93) !important;
} */
svg.unFav{
    color: #434343  !important;
}
/* .headingSection{
    border: 5px solid #000;
} */
.deactive, .devideDiv.deactive{
    pointer-events: none !important;
    cursor: none !important;
}
</style>
