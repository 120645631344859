<template lang="html">
<admin-page />
</template>

<script>
import AdminPage from '@/components/AdminPage.vue';
export default {
    components: {
        AdminPage
    },
    data: () => {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
