<template>
<PrivacyView />
</template>

<script>
import PrivacyView from "./_components/PrivacypolicyView.vue"
export default {
    name: "PrivacyPolicyModule",
    components: {
        PrivacyView
    }
}
</script>
