<template>
<ContactUsView />
</template>

<script>
import ContactUsView from '@/modules/contactus'
export default {
    name: 'ContactusView',
    components: {
        ContactUsView
    }
}
</script>
