<template lang="html">
    <section class="ps-page--my-account">
        <div class="container userDashboard">
            <div class="layout-content  dashboardContaineracct">
                <div class="page-header">
                    <h1 class="text-black acctblock">
                       Favourite Suppliers
                    </h1>
                </div>
                <FavSupplierComponent />
            </div>
        </div>
    </section>
    </template>
    
    <script>
    import FavSupplierComponent from '@/components/users/FavSupplierComponent.vue';
    export default {
        name: 'FavSupplierModule',
        components: {
            FavSupplierComponent
        },
        data: () => {
            return {};
        }
    };
    </script>
    
    <style lang="scss" scoped></style>
    