<template>
<BuyerView />
</template>

<script>
import BuyerView from "./_components/BuyerView.vue"
export default {
    name: "BuyerModule",
    components: {
        BuyerView
    }
}
</script>
