<template>
<HeaderView />
</template>

<script>
import HeaderView from "./_components/HeaderView.vue"
export default {
    name: "HeaderModule",
    components: {
        HeaderView
    }
}
</script>
