<template lang="html">
    <div class="ps-card">
        <div class="ps-card__header custNameHeader">
            <h4>Favorite Suppliers</h4>
        </div>
        <div class="ps-card__content">
            <FavSuppListPage />
        </div>
    </div>
    </template>
    
    <script>
    import FavSuppListPage from '@/components/cards/FavSupplierListPage.vue'
    export default {
        name: ' FavSuppListModule',
        components: {
            FavSuppListPage
        },
        transition: 'zoom',
        data: () => {
            return {};
        },
        methods: {},
        mounted() {},
    };
    </script>
    
    <style lang="scss" scoped></style>
    